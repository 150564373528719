<template>
  <div>
    <div
      :class="['ebl-custom-exp-box', { expanded: active }, { closed: !active }]"
    >
      <div class="ebl-header">
        <v-row no-gutters align="center">
          <v-col :cols="cols_card_title" no-gutters>
            <v-row no-gutters>
              <v-col :cols="cols_card_title_text" no-gutters>
                <span class="card-title">{{ sharedObject.batch_title }}</span>
              </v-col>
              <v-col :cols="cols_card_title_text" no-gutters>
                <span class="text-eval">Evaluation: </span>
                <span class="text-count-student"
                  >{{ evaluatedStudents }}/{{
                    studentExams.length
                  }}
                  Students</span
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col
            :cols="cols_card_action"
            :order="order_action"
            class="d-flex justify-end"
          >
<!--            <v-btn
              :disabled="studentExams.length == 0"
              :loading="publishing[sharedObject.batch_id]"
              depressed
              color="#0099dc"
              class="white&#45;&#45;text custom-btn"
              @click="publish(sharedObject.batch_id)"
            >
              &lt;!&ndash; <v-icon class="btn-icon">share</v-icon> &ndash;&gt;
              <img class="btn-icon" src="../assets/Publish.svg" alt="" />
              <span class="btn-text">Publish</span>
            </v-btn>-->
            <v-btn icon @click="expandContent()">
              <v-icon v-show="!active" :size="arrow_size" :class="arrow_icon"
                >mdi-chevron-down</v-icon
              >
              <v-icon v-show="active" :size="arrow_size" :class="arrow_icon"
                >mdi-chevron-up</v-icon
              >
            </v-btn>
          </v-col>
          <v-col :cols="cols_card_time" :order="order_time">
            <v-row no-gutters>
              <v-col :cols="cols_card_time_sub">
                <span
                  :class="[
                    'inner-box-above-text',
                    { expanded: active },
                    { closed: !active }
                  ]"
                  >Available Date</span
                >
                <span
                  v-if="current_width < 600 && has_time"
                  :class="[
                    'inner-box-above-text ibat-sub',
                    { expanded: active },
                    { closed: !active }
                  ]"
                  >Available Time</span
                >
                <div class="inner-box">
                  <v-row no-gutters>
                    <v-col :cols="cols_inner_box" no-gutters align="center">
                      <span class="to-from-text">From: </span>
                      <span class="date-time-text">{{
                        customDateFormat(sharedObject.start_date)
                      }}</span>
                    </v-col>
                    <v-col :cols="cols_inner_box" no-gutters align="center">
                      <span class="to-from-text date-time-lower">To: </span>
                      <span class="date-time-text">{{
                        customDateFormat(sharedObject.end_date)
                      }}</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col v-show="has_time" :cols="cols_card_time_sub" dense>
                <span
                  v-if="!(current_width < 600)"
                  :class="[
                    'inner-box-above-text',
                    { expanded: active },
                    { closed: !active }
                  ]"
                  >Available Time</span
                >
                <div class="inner-box time-box">
                  <v-row no-gutters>
                    <v-col :cols="cols_inner_box" no-gutters align="center">
                      <span class="to-from-text">From: </span>
                      <span class="date-time-text time-from">{{
                        customTimeFormat(sharedObject.start_time)
                      }}</span>
                    </v-col>
                    <v-col :cols="cols_inner_box" no-gutters align="center">
                      <span class="to-from-text date-time-lower">To: </span>
                      <span class="date-time-text time-to">{{
                        customTimeFormat(sharedObject.end_time)
                      }}</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- <div v-show="active" :class="['ebl-content']"> -->
    <div :class="['ebl-content', { 'content-open': active }]">
      <div v-if="fetchingContent" class="d-flex align-center justify-center">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else-if="studentExams.length == 0" class="no-students">
        <v-row class="my-0">
          <v-col cols="12" align="center" class="pa-0">
            <span>{{networkError ? "Studends have yet to take exam" : "Data unavailable"}}</span>
          </v-col>
        </v-row>
      </div>
      <div
        v-else
        :class="['content-holder', { 'holder-open': active }]"
      >
        <mk-student-list-header
          v-if="!(current_width < 900)"
        ></mk-student-list-header>
        <mk-student-list-card
          v-for="(exam, index) in studentExams"
          :key="index"
          :student-name="student_list[exam.student_id].name"
          :exam="exam"
          :number="index + 1"
        ></mk-student-list-card>
      </div>
    </div>
  </div>
</template>

<script>
import MkStudentListCard from "./MkStudentListCard.vue";
import MkStudentListHeader from "./MkStudentListHeader.vue";

export default {
  name: "MkExpansionPanel",
  components: { MkStudentListCard, MkStudentListHeader },
  props: ["sharedObject", "course_id", "exam_id"],
  data() {
    return {
      fetchingContent: undefined,
      networkError: false,
      active: false,
      studentExams: [],
      publishing: {}
    };
  },
  computed: {
    current_width() {
      return this.$vuetify.breakpoint.width;
    },
    order_time() {
      if (this.current_width < 900) {
        return "3";
      }
      return "2";
    },
    order_action() {
      if (this.current_width < 900) {
        return "2";
      }
      return "3";
    },
    cols_card_title() {
      return this.current_width < 600 ? 7 : this.current_width < 900 ? 6 : 5;
    },
    cols_card_title_text() {
      return this.current_width < 900 ? 12 : 6;
    },
    cols_card_action() {
      return this.current_width < 600 ? 5 : this.current_width < 900 ? 6 : 3;
    },
    cols_card_time() {
      return this.current_width < 600 ? 12 : this.current_width < 900 ? 9 : 4;
    },
    cols_card_time_sub() {
      return this.current_width < 600 ? 12 : 6;
    },
    cols_inner_box() {
      return this.current_width < 600 ? 6 : 12;
    },
    arrow_icon() {
      return this.current_width < 600 ? "ml-4" : "";
    },
    has_time() {
      return !(this.sharedObject.start_time == "");
    },
    student_list() {
      return this.$store.state.student.studentBatchMap[
        this.sharedObject.batch_id
      ];
    },
    evaluatedStudents() {
      let count = 0;
      for (let exam of this.studentExams) {
        if (exam.is_evaluated) {
          count = count + 1;
        }
      }
      return count;
    },
    arrow_size() {
      return this.current_width < 600
        ? "24"
        : this.current_width < 1600
        ? "32"
        : "40";
    },
    can_publish() {
      return this.studentExams.length == this.evaluatedStudents;
    }
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.networkError = true;
      this.fetchingContent = false;
      return;
    }
    this.fetchingContent = true;
    await this.fetchStudentList();
    await this.fetchStudentExams();
    this.fetchingContent = false;
  },
  methods: {
    expandContent() {
      this.active = !this.active;
    },
    customTimeFormat(time) {
      let times = time.split(":");
      if (times[0] > "21") {
        return `${times[0] - "12"}:${times[1]} PM`;
      } else if (times[0] > "12") {
        return `0${times[0] - "12"}:${times[1]} PM`;
      } else if (times[0] > "9") {
        return `${times[0]}:${times[1]} AM`;
      } else {
        return `0${times[0]}:${times[1]} AM`;
      }
    },
    customDateFormat(date) {
      let customDate = new Date(date);
      return customDate.toLocaleString("en-gb", {
        day: "2-digit",
        month: "short",
        year: "numeric"
      });
    },
    async fetchStudentList() {
      try {
        await this.$store.dispatch("student/listBatchStudents", {
          courseId: this.course_id,
          batchId: this.sharedObject.batch_id,
          partner_code: this.$route.params.partner_code
        });
        // return students;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchStudentExams() {
      try {
        this.studentExams = await this.$store.dispatch("exam/getStudentExams", {
          examId: this.exam_id,
          batchId: this.sharedObject.batch_id
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async publish(batchId) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      if(this.publishing[batchId]) return;
      try {
        this.$set(this.publishing, batchId, true);
        await this.$store.dispatch("exam/publishScore", {
          batchId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.publishing, batchId, false);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.expanded {
  background-color: #ebf2ff;
}

.closed {
  background-color: white;
}

.ebl-custom-exp-box {
  /* background-color: white; */
  border: 1px solid #0099dc;
  border-radius: 6px;
  padding-left: 50px;
  padding-right: 45px;
  padding-top: 8px;
  padding-bottom: 20px;
  margin-top: 15px;
  width: 1400px;
  height: 130px;
  position: relative;
  top: 6px;
  z-index: 1;
}

.card-title {
  font-family: roboto;
  font-size: 22px;
  font-weight: 500;
  color: black;
}

.text-eval {
  font-family: roboto;
  font-size: 18px;
  font-weight: 300;
  color: #5b5c5e;
}

.text-count-student {
  font-family: roboto;
  font-size: 18px;
  font-weight: 500;
  color: #202020;
}

.inner-box-above-text {
  font-family: roboto;
  font-size: 16px;
  color: #5b5c5e;
  position: relative;
  top: 13px;
  left: 28px;
  /* background-color: white; */
  padding-left: 5px;
  padding-right: 5px;
}

.inner-box {
  border: 1px solid #0099dc;
  border-radius: 6px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  margin-left: 15px;
  margin-right: 15px;
}

.to-from-text {
  font-size: 16px;
  font-weight: 300;
}

.date-time-text {
  font-size: 18px;
  font-weight: 500;
}

.date-time-lower {
  margin-left: 20px;
}

.time-box {
  padding-right: 30px;
}

.custom-btn {
  /* padding: 25px !important;
  padding-top: 21px !important;
  padding-top: 21px !important; */
  height: 55px !important;
  width: 180px !important;
  border-radius: 6px;
  margin-right: 42px;
}

.btn-icon {
  height: 40px;
  width: 40px;
}

.btn-text {
  font-family: roboto;
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
}

.ebl-content {
  background-color: white;
  border: 1px solid #0099dc;
  border-radius: 0px 0px 6px 6px;
  padding: 0px 20px;
  width: 1400px;
  max-height: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  transition-property: max-height, padding;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0.2s;
}

.content-open {
  max-height: 100vh;
  padding: 5px 20px;
  transition-duration: 0.5s;
  transition-delay: 0s;
}

.content-holder {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}

.holder-open {
  opacity: 1;
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  transition-timing-function: ease-in;
}

.no-students {
  padding: 0px;

  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1600px) {
  .ebl-custom-exp-box {
    padding: 28px;
    padding-top: 5px;
    padding-bottom: 15px;
    padding-right: 20px;
    width: 950px;
    height: 105px;
  }

  .card-title {
    font-size: 16px;
  }

  .text-eval {
    font-size: 12px;
  }

  .text-count-student {
    font-size: 12px;
  }

  .inner-box-above-text {
    font-size: 11px;
    top: 11px;
    left: 11px;
  }

  .inner-box {
    padding-top: 5px;
    padding-left: 12px;
    padding-bottom: 5px;
    margin-left: -2px;
    margin-right: 25px;
  }

  .time-box {
    padding-right: 20px;
  }

  .to-from-text {
    font-family: roboto;
    font-size: 12px;
    font-weight: 300;
  }

  .date-time-text {
    font-family: roboto;
    font-size: 12px;
    font-weight: 500;
  }

  .date-time-lower {
    margin-left: 15px;
  }

  .custom-btn {
    /* padding: 20px !important; */
    height: 45px !important;
    width: 140px !important;
    margin-right: 24px;
  }

  .btn-icon {
    height: 30px;
    width: 30px;
  }

  .btn-text {
    font-size: 16px;
    margin-left: 6px;
  }

  .ebl-content {
    width: 950px;
  }
}

@media only screen and (max-width: 899px) {
  .ebl-custom-exp-box {
    margin-top: 8px;
    padding: 28px;
    padding-bottom: 20px;
    padding-right: 10px;
    width: 505px;
    height: 185px;
    top: 11px;
  }

  .card-title {
    font-size: 16px;
  }

  .text-eval {
    font-size: 12px;
  }

  .text-count-student {
    font-size: 12px;
  }

  .inner-box-above-text {
    font-size: 11px;
    top: 11px;
    left: 17px;
  }

  .inner-box {
    padding-top: 5px;
    padding-right: 30px;
    padding-bottom: 8px;
    margin-left: 0px;
    margin-right: 15px;
  }

  .time-box {
    padding-right: 40px;
  }

  .to-from-text {
    font-family: roboto;
    font-size: 12px;
    font-weight: 300;
  }

  .date-time-text {
    font-family: roboto;
    font-size: 12px;
    font-weight: 500;
  }

  .date-time-lower {
    margin-left: 20px;
  }

  .custom-btn {
    margin-right: 10px;
  }

  .ebl-content {
    width: 505px;
    padding: 0px 10px;
    padding-top: 0px;
  }

  .content-open {
    padding: 5px 10px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 599px) {
  .ebl-custom-exp-box {
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 8px;
    width: 305px;
    height: revert;
  }

  .card-title {
    font-size: 12px;
  }

  .text-eval {
    font-size: 11px;
  }

  .text-count-student {
    font-size: 11px;
  }

  .inner-box-above-text {
    font-size: 10px;
    top: 11px;
    left: 10px;
  }

  .ibat-sub {
    top: 65px;
    left: -64px;
  }

  .inner-box {
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .time-box {
    margin-top: 15px;
  }

  .to-from-text {
    font-size: 11px;
    font-weight: 300;
  }

  .date-time-text {
    font-size: 11px;
    font-weight: 500;
  }

  .date-time-lower {
    margin-left: 12px;
  }

  .time-to {
    padding-right: 16px;
  }

  .time-from {
    padding-right: 16px;
  }

  .custom-btn {
    margin-right: 5px;
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
  }

  .btn-icon {
    height: 24px;
    width: 24px;
  }

  .btn-text {
    display: none;
  }

  .ebl-content {
    width: 305px;
    padding-top: 0px;
  }

  .content-open {
    padding-top: 5px;
  }
}

.no-students {
  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) and (max-width: 1599px) {
    font-size: 12px;
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    font-size: 12px;
  }

  @media screen and (max-width: 599px) {
    font-size: 11px;
  }
}
</style>
