var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        attrs: {
          id: "exam-blp",
          "page-title": !_vm.$language
            ? _vm.$route.meta.title
            : "ব্যাচে শেয়ার করা হয়েছে",
        },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-2",
                    attrs: { color: "black", icon: "", large: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          name: "exams",
                        })
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("arrow_back")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "action",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "my-0" },
                      [
                        _vm.showDuration
                          ? _c("v-col", {
                              staticClass: "d-flex align-center px-0",
                            })
                          : _vm._e(),
                        _vm.showDuration
                          ? _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center px-0",
                              },
                              [
                                _c("img", {
                                  staticClass: "icon-app",
                                  attrs: {
                                    src: require("../../modules/exam/assets/Share With Batches.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("span", { staticClass: "batch-info" }, [
                                  _vm._v(
                                    _vm._s(!_vm.$language ? "With " : "") +
                                      " " +
                                      _vm._s(_vm.numShares) +
                                      " " +
                                      _vm._s(
                                        !_vm.$language
                                          ? "Batches"
                                          : "টি ব্যাচের সাথে শেয়ার করা হয়েছে"
                                      )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm.showDuration
                          ? _c(
                              "v-col",
                              { staticClass: "d-flex align-center px-0" },
                              [
                                _c("img", {
                                  staticClass: "icon-app",
                                  attrs: {
                                    src: require("../../modules/exam/assets/Duration.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("span", { staticClass: "time-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.$language ? "Duration: " : "সময়কালঃ "
                                    )
                                  ),
                                ]),
                                _c("span", { staticClass: "time-value" }, [
                                  _vm._v(
                                    _vm._s(_vm.examsList.duration) +
                                      " " +
                                      _vm._s(!_vm.$language ? "Min" : "মিনিট")
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("div", { staticClass: "my-5" }),
      _c("v-container", { staticClass: "py-0 my-0" }, [
        !_vm.showDuration
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", {
                      class: [
                        "d-flex",
                        "align-center",
                        "py-0",
                        { "pr-0": _vm.screenSize < 600 },
                      ],
                      attrs: { cols: _vm.cols_top_bar_elem1 },
                    }),
                    _c(
                      "v-col",
                      {
                        class: [
                          "d-flex",
                          "align-center",
                          "py-0",
                          { "px-0": _vm.screenSize < 600 },
                        ],
                        attrs: { cols: _vm.cols_top_bar_elem2 },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-app",
                          attrs: {
                            src: require("../../modules/exam/assets/Share With Batches.svg"),
                            alt: "",
                          },
                        }),
                        _c("span", { staticClass: "batch-info" }, [
                          _vm._v("With " + _vm._s(_vm.numShares) + " Batches"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("exam-batch-list", {
        attrs: { "course-id": _vm.courseId, "exam-id": _vm.examId },
        on: { sharedCount: _vm.setSharedBatchCount },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }