var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0" },
    [
      _vm.fetchingContent
        ? [
            _c("div", { attrs: { id: "loading-wrapper" } }, [
              _c("div", { attrs: { id: "loading-text" } }, [_vm._v("LOADING")]),
              _c("div", { attrs: { id: "loading-content" } }),
            ]),
          ]
        : _vm.networkError
        ? [
            _c("no-content-component", {
              attrs: { message: _vm.networkErrorNoContentMessage },
            }),
          ]
        : _vm.noContentCondition
        ? [
            _c("no-content-component", {
              attrs: { message: _vm.noContentMessage },
            }),
          ]
        : _vm._l(_vm.shares, function (item, index) {
            return _c("mk-expansion-panel", {
              key: index,
              attrs: {
                "shared-object": item,
                course_id: _vm.courseId,
                exam_id: _vm.examId,
              },
            })
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }