var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-style", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_name },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: _vm.cols_name_part } }, [
                    _c("span", { staticClass: "num-text" }, [_vm._v(" No. ")]),
                    _c("span", { staticClass: "name-text" }, [
                      _vm._v(" Name "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: _vm.cols_grade_part } }, [
                    _c("span", [_vm._v(" Status ")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_time },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", [
                    _c("span", { staticClass: "date-text" }, [
                      _vm._v(" Date Appeared "),
                    ]),
                  ]),
                  _c("v-col", [
                    _c("span", { staticClass: "time-text" }, [
                      _vm._v(" Time Appeared "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_button },
            },
            [_c("span", { staticClass: "action-text" }, [_vm._v(" Actions ")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }