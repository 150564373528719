<template>
  <v-container class="py-0">
    <template v-if="fetchingContent">
      <div id="loading-wrapper">
        <div id="loading-text">LOADING</div>
        <div id="loading-content"></div>
      </div>
    </template>
    <template v-else-if="networkError">
        <no-content-component
        :message="networkErrorNoContentMessage"
      ></no-content-component>
      </template>
    <template v-else-if="noContentCondition">
      <no-content-component :message="noContentMessage"></no-content-component>
    </template>
    <mk-expansion-panel
      v-else
      v-for="(item, index) in shares"
      :key="index"
      :shared-object="item"
      :course_id="courseId"
      :exam_id="examId"
    >
    </mk-expansion-panel>
  </v-container>
</template>

<script>
import batchService from "@ecf/modules/batch/services/BatchService";
import MkExpansionPanel from "./MkExpansionPanel.vue";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "ExamBatchList",
  components: { MkExpansionPanel, NoContentComponent },
  props: {
    examId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
    partner_code: String,
  },
  data() {
    return {
      fetchingContent: true,
      networkError: false,
      batches: [],
      publishing: {},
    };
  },
  computed: {
    shares() {
      return this.$store.state.exam.examShares[this.examId];
    },
    noContentCondition() {
      return Object.keys(this.shares)?.length == 0;
    },
    noContentMessage() {
      return this.$language
        ? "বর্তমানে এই পরীক্ষা কোনো ব্যাচ এর সাথে শেয়ার করা নেই"
        : "Currently the exam is not being shared with any batches";
    },
    networkErrorNoContentMessage() {
      return !this.$language
        ? "Your exam submissions are currently not available"
        : "বর্তমানে আপনার পরীক্ষা জমাদান সংক্রান্ত তথ্য এনে দেয়া সম্ভব হচ্ছে না";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if (!isOnline) {
      this.$root.$emit("alert", [
        "Alert",
        "There might be an internet connection issue on your system",
      ]);
      this.networkError = true;
      this.fetchingContent = false;
      return;
    }
    this.fetchingContent = true;
    await this.fetchBatches();
    await this.fetchShares();
    this.$emit("sharedCount", Object.entries(this.shares).length);
    this.fetchingContent = false;
  },
  methods: {
    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.courseId);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e),
        ]);
      }
    },
    async fetchShares() {
      try {
        await this.$store.dispatch("exam/listSharedBatches", {
          examId: this.examId,
          courseId: this.courseId,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },

    async publish(batchId) {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        return;
      }
      try {
        this.$set(this.publishing, batchId, true);
        await this.$store.dispatch("exam/publishScore", {
          batchId,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.publishing, batchId, false);
      }
    },
  },
};
</script>

<style scoped></style>
