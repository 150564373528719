<template>
  <v-card flat class="card-style">
    <v-row no-gutters>
      <v-col :cols="cols_name" class="d-flex align-center">
        <v-row no-gutters>
          <v-col :cols="cols_name_part">
            <span class="num-text">
              No.
            </span>
            <span class="name-text">
              Name
            </span>
          </v-col>
          <v-col :cols="cols_grade_part">
            <span>
              Status
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="cols_time" class="d-flex align-center">
        <v-row no-gutters>
          <v-col
            ><span class="date-text">
              Date Appeared
            </span></v-col
          >
          <v-col
            ><span class="time-text">
              Time Appeared
            </span></v-col
          >
        </v-row>
      </v-col>
      <v-col :cols="cols_button" class="d-flex align-center">
        <span class="action-text">
          Actions
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MkStudentListHeader",
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    cols_name() {
      return this.screenSize < 900 ? 7 : 7;
    },
    cols_name_part() {
      return this.screenSize < 900 ? 12 : 8;
    },
    cols_grade_part() {
      return this.screenSize < 900 ? 12 : 4;
    },
    cols_time() {
      return this.screenSize < 900 ? 5 : 3;
    },
    cols_button() {
      return this.screenSize < 900 ? 5 : 2;
    }
  }
};
</script>

<style scoped>
.card-style {
  margin-top: 10px;
  padding: 15px 40px;
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
}

.name-text {
  margin-left: 10px;
}

.action-text {
  margin-left: 85px;
}

@media only screen and (max-width: 1600px) {
  .card-style {
    margin-top: 6px;
    padding: 8px 20px;
    font-size: 14px;
  }

  .name-text {
    margin-left: 6px;
  }

  .action-text {
    margin-left: 45px;
  }
}
</style>
