<template>
  <v-card flat class="card-style">
    <v-row no-gutters>
      <v-col :cols="cols_name" class="d-flex align-center">
        <v-row no-gutters>
          <v-col :cols="cols_name_part">
            <span class="num-text">
              {{ adjustNumFormat(number) }}
            </span>
            <span class="name-text">
              {{ studentName }}
            </span>
          </v-col>
          <v-col :cols="cols_grade_part">
            <div class="grading-section">
              <span v-if="screenSize < 900" class="dtg-desc">Grade:</span>
              <span class="status" v-html="evaluationStatus(exam)"></span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="cols_time" :order="order_time" class="d-flex align-center">
        <v-row class="py-3">
          <v-col :cols="cols_dt" class="py-0">
            <span v-if="screenSize < 900" class="dtg-desc">Date Appeared:</span>
            <span class="date-text">{{ customDateFormat(exam.start_at) }}</span>
          </v-col>
          <v-col :cols="cols_dt" class="py-0">
            <span v-if="screenSize < 900" class="dtg-desc">Time Appeared:</span>
            <span class="time-text">{{ customTimeFormat(exam.start_at) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        :cols="cols_button"
        :order="order_button"
        class="d-flex align-center"
      >
        <!-- <v-btn :disabled="exam.is_evaluated" depressed color="#0099dc" class="eval-btn" :to="{ -->
        <v-btn
          depressed
          color="primary"
          class="eval-btn"
          :to="{
            name: 'fluent-student-exam-details',
            params: { studentExamId: exam.id }
          }"
        >
          <span class="btn-text">{{ exam.is_evaluated ? `Re-evaluate` : `Evaluate` }}</span>
        </v-btn>
<!--        <v-btn v-else depressed color="white" class="eval-btn inert">
          <span class="btn-text primary&#45;&#45;text">Published</span>
        </v-btn>-->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MkStudentListCard",
  props: ["exam", "studentName", "number"],
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    cols_name() {
      return this.screenSize < 900 ? 8 : 7;
    },
    cols_name_part() {
      return this.screenSize < 900 ? 12 : 8;
    },
    cols_grade_part() {
      return this.screenSize < 900 ? 12 : 4;
    },
    cols_time() {
      return this.screenSize < 600 ? 12 : this.screenSize < 900 ? 10 : 3;
    },
    cols_dt() {
      return this.screenSize < 600 ? 12 : 6;
    },
    cols_button() {
      return this.screenSize < 900 ? 4 : 2;
    },
    order_time() {
      return this.screenSize < 900 ? "3" : "2";
    },
    order_button() {
      return this.screenSize < 900 ? "2" : "3";
    },
  },
  methods: {
    adjustNumFormat(num) {
      if (num < 10) {
        return `0${num}`;
      }
      return num;
    },
    customDateFormat(date) {
      let customDate = new Date(date);
      return customDate.toLocaleString("en-gb", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
    customTimeFormat(date) {
      let customDate = new Date(date);
      return customDate.toLocaleString("en-gb", {
        hour12: "true",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    evaluationStatus(examObject) {
      if (examObject.is_evaluated) {
        return `<span style="color: #0099dc;">${examObject.score.toFixed(
          1
        )}</span>`;
      } else {
        return `<span style="color: #FC6222">Not Evaluated</span>`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-style {
  margin-top: 10px;
  border: 0.5px solid #b7b7b7;
  border-radius: 6px;
  padding: 15px 40px;
  font-family: roboto;
  font-weight: 400;
  font-size: 18px;
  color: #202020;
}

.name-text {
  margin-left: 10px;
}

.eval-btn {
  height: 50px !important;
  width: 120px !important;
  margin-left: 85px;
  border-radius: 6px;
}

.btn-text {
  font-family: roboto;
  font-weight: 400;
  font-size: 18px;
  color: white;
}

@media only screen and (max-width: 1600px) {
  .card-style {
    margin-top: 6px;
    padding: 8px 20px;
    font-size: 14px;
  }

  .name-text {
    margin-left: 6px;
  }

  .eval-btn {
    height: 35px !important;
    width: 90px !important;
    margin-left: 45px;
  }

  .btn-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .card-style {
    font-size: 12px;
    padding: 20px;
    padding-bottom: 5px;
  }

  .num-text {
    font-size: 14px;
    font-weight: 500;
  }

  .name-text {
    font-size: 14px;
    font-weight: 500;
  }

  .date-text {
    font-weight: 500;
  }

  .time-text {
    font-weight: 500;
  }

  .eval-btn {
    height: 35px !important;
    width: 90px !important;
    margin-left: 55px;
  }

  .dtg-desc {
    color: #5b5c5e;
    font-weight: 300;
    margin-right: 10px;
  }

  .grading-section {
    position: relative;
    top: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .card-style {
    padding: 10px;
    padding-bottom: 4px;
  }

  .num-text {
    font-size: 11px;
  }

  .name-text {
    font-size: 11px;
  }

  .date-text {
    font-size: 11px;
  }

  .time-text {
    font-size: 11px;
  }

  .eval-btn {
    height: 35px !important;
    width: 90px !important;
    margin-left: 0px;
  }

  .dtg-desc {
    font-size: 10px;
    margin-right: 4px;
  }

  .grading-section {
    position: relative;
    top: 5px;
  }
}
</style>
