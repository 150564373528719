var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-style", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_name },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: _vm.cols_name_part } }, [
                    _c("span", { staticClass: "num-text" }, [
                      _vm._v(
                        " " + _vm._s(_vm.adjustNumFormat(_vm.number)) + " "
                      ),
                    ]),
                    _c("span", { staticClass: "name-text" }, [
                      _vm._v(" " + _vm._s(_vm.studentName) + " "),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: _vm.cols_grade_part } }, [
                    _c("div", { staticClass: "grading-section" }, [
                      _vm.screenSize < 900
                        ? _c("span", { staticClass: "dtg-desc" }, [
                            _vm._v("Grade:"),
                          ])
                        : _vm._e(),
                      _c("span", {
                        staticClass: "status",
                        domProps: {
                          innerHTML: _vm._s(_vm.evaluationStatus(_vm.exam)),
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_time, order: _vm.order_time },
            },
            [
              _c(
                "v-row",
                { staticClass: "py-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: _vm.cols_dt } },
                    [
                      _vm.screenSize < 900
                        ? _c("span", { staticClass: "dtg-desc" }, [
                            _vm._v("Date Appeared:"),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "date-text" }, [
                        _vm._v(_vm._s(_vm.customDateFormat(_vm.exam.start_at))),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: _vm.cols_dt } },
                    [
                      _vm.screenSize < 900
                        ? _c("span", { staticClass: "dtg-desc" }, [
                            _vm._v("Time Appeared:"),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "time-text" }, [
                        _vm._v(_vm._s(_vm.customTimeFormat(_vm.exam.start_at))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "d-flex align-center",
              attrs: { cols: _vm.cols_button, order: _vm.order_button },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "eval-btn",
                  attrs: {
                    depressed: "",
                    color: "primary",
                    to: {
                      name: "fluent-student-exam-details",
                      params: { studentExamId: _vm.exam.id },
                    },
                  },
                },
                [
                  _c("span", { staticClass: "btn-text" }, [
                    _vm._v(
                      _vm._s(_vm.exam.is_evaluated ? `Re-evaluate` : `Evaluate`)
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }