<template>
  <v-container>
    <page-title
      id="exam-blp"
      :page-title="!$language ? $route.meta.title : 'ব্যাচে শেয়ার করা হয়েছে'"
    >
      <template #left>
        <v-btn
          class="mr-2"
          color="black"
          icon
          large
          @click="
            $router.push({
              name: 'exams',
            })
          "
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </template>
      <template #action>
        <div class="top">
          <v-row class="my-0">
            <v-col v-if="showDuration" class="d-flex align-center px-0">
              <!-- <span class="time-text">Upload Date: </span>
              <span class="time-value"></span> 
              The above code will be necessary once appropriate back endchanges are made-->
            </v-col>
            <v-col
              v-if="showDuration"
              class="d-flex align-center justify-center px-0"
            >
              <img
                class="icon-app"
                src="../../modules/exam/assets/Share With Batches.svg"
                alt=""
              />
              <span class="batch-info"
                >{{ !$language ? "With " : "" }} {{ numShares }}
                {{
                  !$language ? "Batches" : "টি ব্যাচের সাথে শেয়ার করা হয়েছে"
                }}</span
              >
            </v-col>
            <v-col v-if="showDuration" class="d-flex align-center px-0">
              <img
                class="icon-app"
                src="../../modules/exam/assets/Duration.svg"
                alt=""
              />
              <span class="time-text">{{
                !$language ? "Duration: " : "সময়কালঃ "
              }}</span>
              <span class="time-value"
                >{{ examsList.duration }}
                {{ !$language ? "Min" : "মিনিট" }}</span
              >
            </v-col>
<!--            <v-col class="d-flex justify-end">
              <v-btn
                color="#0099dc"
                class="publish-all-button white&#45;&#45;text"
                :loading="publishing['all']"
                @click="massPublish()"
              >
                <img
                  class="publish-all-icon"
                  src="../../modules/exam/assets/Publish All Batch.svg"
                  alt=""
                />
                <span v-if="showDuration" class="publish-all-text">{{
                  !$language ? "Publish All Batch" : "সকল ব্যাচ প্রকাশ করুন"
                }}</span>
              </v-btn>
            </v-col>-->
          </v-row>
        </div>
      </template>
    </page-title>
    <div class="my-5"></div>
    <v-container class="py-0 my-0">
      <div v-if="!showDuration">
        <v-row>
          <v-col
            :cols="cols_top_bar_elem1"
            :class="[
              'd-flex',
              'align-center',
              'py-0',
              { 'pr-0': screenSize < 600 },
            ]"
          >
            <!-- <span class="time-text">Upload Date: </span>
            <span class="time-value">{{ uploadDate }}</span> 
            As mentioned above-->
          </v-col>
          <v-col
            :cols="cols_top_bar_elem2"
            :class="[
              'd-flex',
              'align-center',
              'py-0',
              { 'px-0': screenSize < 600 },
            ]"
          >
            <img
              class="icon-app"
              src="../../modules/exam/assets/Share With Batches.svg"
              alt=""
            />
            <span class="batch-info">With {{ numShares }} Batches</span>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <exam-batch-list
      :course-id="courseId"
      :exam-id="examId"
      @sharedCount="setSharedBatchCount"
    ></exam-batch-list>
  </v-container>
</template>

<script>
import ExamBatchList from "#ecf/exam/components/ExamBatchList";

export default {
  name: "ExamBatchListPage",
  components: {
    ExamBatchList,
  },
  // inject: {
  //   $courseOwnerId,
  // },
  props: {
    courseId: {
      type: String,
      required: true,
    },
    examId: {
      type: String,
      required: true,
    },
  },
  inject: ["$courseOwnerId"],
  data() {
    return {
      examsList: {},
      numShares: 0,
      publishing: {},
      uploadDate: "",
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    showDuration() {
      return this.screenSize < 900 ? false : true;
    },
    cols_top_bar_elem1() {
      return this.screenSize < 600 ? 6 : 5;
    },
    cols_top_bar_elem2() {
      return this.screenSize < 600 ? 6 : 5;
    },
    batchIds() {
      let shares = this.$store.state.exam.examShares[this.examId];
      let cup = [];
      for (let index in shares) {
        cup.push(shares[index].batch_id);
      }
      return cup;
    },
    // uploadDate(){
    //   should return upload date once back end changes have been made
    // }
  },
  async created() {
    await this.fetchExams();
  },
  methods: {
    async onDone() {
      await this.$router.replace({ name: "exams" });
    },
    async cancel() {
      this.$router.back();
    },
    async fetchExams() {
      try {
        this.examsList = await this.$store.dispatch("exam/get", {
          id: this.examId,
          course_id: this.courseId,
          owner_id: this.$courseOwnerId(),
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchShares() {
      try {
        await this.$store.dispatch("exam/listSharedBatches", {
          examId: this.examId,
          courseId: this.courseId,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async massPublish() {
      if(this.publishing) return;
      try {
        this.$set(this.publishing, "all", true);
        for (let batchId of this.batchIds) {
          await this.$store.dispatch("exam/publishScore", {
            batchId,
          });
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.$set(this.publishing, "all", false);
      }
    },
    setSharedBatchCount(value) {
      this.numShares = value;
    },
    customDateFormat(date) {
      let customDate = new Date(date);
      return customDate.toLocaleString("en-gb", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped>
#exam-blp {
  max-width: 100% !important;
}

.top {
  width: 850px;
  margin-left: auto;
}

.icon-app {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.time-text {
  font-family: roboto;
  font-size: 16px;
  font-weight: 400;
  color: #0000008c;
}

.time-value {
  font-family: roboto;
  font-size: 16px;
  font-weight: 400;
  color: #0099dc;
  margin-left: 10px;
}

.batch-info {
  font-family: roboto;
  font-size: 16px;
  font-weight: 500;
  color: #202020;
}

.publish-all-button {
  height: 55px !important;
  width: 250px !important;
}

.publish-all-icon {
  height: 40px;
  width: 40px;
}

.publish-all-text {
  color: white;
  font-size: 20px;
  font-family: roboto;
  margin-left: 10px;
}

@media only screen and (max-width: 1600px) {
  .top {
    width: 650px;
  }

  .icon-app {
    height: 25px;
    width: 25px;
    margin-right: 6px;
  }

  .time-text {
    font-family: roboto;
    font-size: 12px;
    font-weight: 400;
    color: #202020;
  }

  .time-value {
    font-family: roboto;
    font-size: 12px;
    font-weight: 400;
    color: #0099dc;
    margin-left: 10px;
  }

  .batch-info {
    font-family: roboto;
    font-size: 12px;
    font-weight: 500;
    color: #202020;
  }

  .publish-all-button {
    height: 45px !important;
    width: 200px !important;
  }

  .publish-all-icon {
    height: 30px;
    width: 30px;
  }

  .publish-all-text {
    font-size: 16px;
    margin-left: 6px;
  }
}

@media only screen and (max-width: 900px) {
  .publish-all-button {
    height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
  }

  .publish-all-icon {
    height: 28px;
    width: 28px;
  }
}

@media only screen and (max-width: 600px) {
  .time-text {
    font-size: 10px;
  }

  .time-value {
    font-size: 10px;
    margin-left: 6px;
  }

  .batch-info {
    font-size: 11px;
  }

  .publish-all-button {
    height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
  }

  .publish-all-icon {
    height: 28px;
    width: 28px;
  }
}
</style>
